.Frota{
    padding: 75px 10% 75px 10%;
    color: #fff;
    background:
        linear-gradient(
        #ffffffaa,#ccccccaa
        ),
        url(../../imgs/fundofrota.jpg);
        background-size: cover;
        background-position: center;
    -webkit-box-shadow: 0px 0px 10px 5px #fff; 
    box-shadow: 0px 0px 10px 5px #fff;
}

.Nav{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.Frota h1{
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    color: #016fb9;
}

.Frota h3{
    font-size: large;
    letter-spacing: 1px;
}

.galeriaFrota{
    width: 12%;
    height: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 5px 5px 0 0;
    color: #ccc;
    cursor: pointer;
    background: #192640;
}

.galeriaFrota a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 15px;
}

.galeriaFrota a:hover{
    text-decoration: none;
    border: 2px solid #ccc;
    color: #fff;
    font-size: large;
    -webkit-transition: all 0.5s 0s ease;
    -moz-transition: all 0.5s 0s ease;
    -o-transition: all 0.5s 0s ease;
    transition: all 0.5s 0s ease;
}

.cardFrota{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding-top: 10px;
    background:#192640;
}

.Frota video{
    width: 100%;
    height: auto;
}

.videogaleria{
    width: 100%;
    height:500px;
}

.videogaleria1{
    background:url(../../imgs/frota/util.jpg);
    background-size: cover;
    background-position: center;
}

.videogaleria2{
    background:url(../../imgs/frota/34.jpg);
    background-size: cover;
    background-position: left;
}

.videogaleria3{
    background:url(../../imgs/frota/truck.jpg);
    background-size: cover;
    background-position: center;
}

.videogaleria4{
    background:url(../../imgs/frota/Carreta.jpg);
    background-size: cover;
    background-position: center;
}

.videogaleria5{
    background:url(../../imgs/frota/guincho1.jpg);
    background-size: cover;
    background-position: center;
}

.videogaleria6{
    background:url(../../imgs/frota/munck1.jpeg);
    background-size: cover;
    background-position: center;
}

.videogaleria7{
    background:url(../../imgs/frota/silo.jpg);
    background-size: cover;
    background-position: center;
}

.videogaleria8{
    background:url(../../imgs/frota/prancha.jpg);
    background-size: cover;
    background-position: center;
}

@media only screen and (max-width: 1000px) {
    .galeriaFrota{
        width: 24%;
        margin-bottom: 10px;
        border: none;
        border-radius: 5px;
    }

    .videogaleria{
        height: 200px;
        border-radius: 5px;
    }
}
.menuToggle{
    padding: 0;
    margin: 0;
    background: #fff;
    background: linear-gradient(180deg, #ffffffdd 0%, #ffffffcc 100%);
    position: absolute;
    right: 0;
    top: 65px;
    width: 300px;
}

.menuToggle a{
    width: 100%;
    text-decoration: none;
}

.menuToggle ul{
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

.menuToggle a li{
    width: 100%;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
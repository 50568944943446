@keyframes transFundo {
    0% {background-position: 0px;}
    50% {background-position: 500px;}
    100% {background-position: 0px;}
}

.Contato{
    background:
    url(../../imgs/fundo.jpg);
    background-size: cover;
    padding: 50px 10% 50px 10%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    animation-name: transFundo;
    animation-duration: 15s;
    animation-iteration-count: infinite;
}

.menucontato{
    color: #fff;
    background: #192640;
}

.form{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    font-size: smaller;
    font-weight: bold;
    color: #fff;
    padding: 30px;
    background: #016fb9cc;
    border-radius: 8px;
    margin-top: 15px;
    height: 85vh;
}

.form label{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}

form{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

form input{
    width: calc(100%);
    padding: 9px;
    margin-bottom: 15px;
    border-radius: 0 5px 5px 0;
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
    font-family:Arial, Helvetica, sans-serif;
}

form textarea{
    width: calc(100%);
    border-radius: 0 5px 5px 0;
    height: 60px;
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
}

form button{
    width: 100%;
    height: 60px;
    border: none;
    font-weight: bold;
    color: #fff;
    border-radius: 8px;
    background-color: #333;
    margin-top: -2px;
    font-size: medium;

    display: flex;
    justify-content: center;
    align-items: center;
}

.acoes{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.dados{
    width: 100%;
    padding: 20px 30px 30px 30px;
    margin-top: 15px;
    background: #016fb9cc;
    border-radius: 8px;
    height: 81vh;
}

.dados p{
    color: #fff;
    line-height: 1.8rem;
}

.dados p i{
    margin-right: 5px;
}

.dados a{
    color: #fff;
    font-size: x-large;
    margin-top: 10px;
    width: 100%;

}

.dados a .contatoItens{
    width: 100%;
    height: 60px;
    background-color: #333;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dados .btContato{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.dados .btContato a:hover{
    text-decoration: none;
}

.dados .rede1:hover{
    background: #EA4335;
    color: #fff;
    -webkit-transition: all 0.8s 0s ease;
    -moz-transition: all 0.8s 0s ease;
    -o-transition: all 0.8s 0s ease;
    transition: all 0.8s 0s ease;
}

.dados .btContato .rede2:hover{
    background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
    color: #fff;
}

.dados .rede3:hover{
    background: #3b5998;
    color: #fff;
    -webkit-transition: all 0.8s 0s ease;
    -moz-transition: all 0.8s 0s ease;
    -o-transition: all 0.8s 0s ease;
    transition: all 0.8s 0s ease;
}

.imgcontato{
    width: 45%;
    background: url(../../imgs/contato.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.menucontato{
    width: 45%;
    font-size: small;
    font-weight: bold;
    cursor: pointer;
}

.telefone{
    font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 800px) {
    .form{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        height: auto;
    }
    .dados{
        width: 100%;
        height: auto;
    }

    .imgcontato{
        display: none;
    }

    .menucontato{
        width: 100%;
    }
}
.Servicos{
    background:
        linear-gradient(
        #192640ff,#015fb9cc
        ),
        url(../../imgs/fundoservice.jpg);
        background-size: cover;
        background-position: top;
    padding: 75px 10% 75px 10%;
    color: #fff;
}

.Servicos h1{
    text-align: center;
    color: #fff;
}

.Servicos .servicoCards{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
}

.Servicos .servicoCards .servico{
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #fff;
    padding: 30px;
    background-color: #333333cc;
}

.Servicos .servicoCards .servico p{
    padding: 0;
    margin: 0;
    width: 100%;
    margin-top: 20px;
    line-height: 1.8rem;
    text-align: center;
    font-size: small;
}

.Servicos .servicoCards .s1{
    border-radius: 8px 0px 0px 8px;
}

.s2{
    border-radius: 0 8px 8px 0;
}


@media only screen and (max-width: 1000px) {
    .Servicos .servicoCards .servico{
        width: 100%;
        border-radius: 0;
    } 
    .Servicos .servicoCards .s1{
        border-radius: 8px 8px 0 0;
    }
    .Servicos .servicoCards .s6{
        border-radius: 0 0 8px 8px;
    }
}
.toggle{
    width: 100%;
}

.toggle button{
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 0 20px 0;
    border-bottom: 2px solid #aaa;
}

.toggle h3{
    color: #fff;
    font-size: x-large;
    margin: 0;
    padding: 0;
    margin-top: 20px;
}

.service2{
    width: 150px;
    height: 150px;
    background: url(../imgs/aaa.jpg);
    border-radius: 75px;
    background-size: cover;
    background-position: right;
    margin-bottom: 20px;
}

.service1{
    width: 150px;
    height: 150px;
    background: url(../imgs/service2.jpg);
    border-radius: 75px;
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
}
/* Definição da área de trabalho */
.App{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #333;
    overflow-x: hidden;
}

/* Definição do topo */

.header{
    width: 100%;
    height: 80px;
    padding: 0 20px 0 20px;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    position: absolute;
    background: #fff;
    background: linear-gradient(270deg, #ffffffff 0%, #ffffffaa 100%);
}

/* Logo */


.header .logoa{
    height: 50px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.header .logob{
    height: 50px;
    display: flex;
    align-items: center;
}

.imgLogo{
    width: 300px;
    height: 50px;
}

.isobt{
    padding: 0;
    border: none;
    background: transparent;
}

.iso{
    height: 60px;
}

/* Menu */
.menuGrande{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.header ul{
    list-style: none;
    padding: 0;
    margin: 0;
    width: calc(100% - 200px);
    height: 100%;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-right: 90px;
}

.header a{
    margin-left: 10px;
    text-decoration: none;
}

.header li{
    height: 60px;
    padding: 0 21px 0 20px;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.menuGrande a li{
    text-decoration: none;
    color: #1c417b;
    text-transform: uppercase;
    font-size: medium;
}

.menuGrande a:hover{
    color: #1c417b;
    text-decoration: none;
}

.menuGrande a li:hover{
    background-color: #192640;
    color: #fff;
    -webkit-transition: all 0.8s 0s ease;
    -moz-transition: all 0.8s 0s ease;
    -o-transition: all 0.8s 0s ease;
    transition: all 0.8s 0s ease;
}

/* Renderização responsiva do menu */

@media only screen and (max-width: 1000px) {
    .menuHidden {
      display: none;
    }
    .header .logo{
        display: none;
        width: 80px;
    }
    .header{
        position: fixed;
    }
}

@media only screen and (min-width: 1000px) {
    .header .logob{
        display: none;
    }
}

.toggleMenu{
    position: fixed;
    z-index: 1;
    right: 20px;
    top: 20px;
}

@media only screen and (max-width: 1000px) {
    .toggleMenu{
        z-index: 99;
    }
    .header .logob{
        position: fixed;
        top: 17px;
    }
}

/* Botão pro topo */

.btTop{
    position: fixed;
    z-index: 3;
    bottom: 0;
    right: 0;
    margin-bottom: 15px;
    margin-right: 15px;
    font-size: xx-large;
    display: flex;
    flex-direction: column;
}

.topButton{
    border: none;
    color: #75c043;
    font-size: 32px;
    background: #fff;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.whatsButton{
    border: none;
    background-color: #25D165;
    font-size: 36px;
    color: #fff;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.whatsButton:hover{
    transform: rotate(360deg);
    -webkit-transition: all 1.5s 0s ease;
    -moz-transition: all 1.5s 0s ease;
    -o-transition: all 1.5s 0s ease;
    transition: all 1.5s 0s ease;
}

/* Rodapé */

.footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #016fb9;
    padding-top: 15px;
}

.menuRodape {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #333;
    padding-top: 40px;
}

.menuRodape a{
    color: #fff;
    font-size: small;
}

.menuRodape a:hover{
    color: #feca16;
    text-decoration: none;
    -webkit-transition: all 0.5s 0s ease;
    -moz-transition: all 0.5s 0s ease;
    -o-transition: all 0.5s 0s ease;
    transition: all 0.5s 0s ease;
}

.menuRodape i{
    color: #1c417d;
    font-size: 5px;
    margin-left: 10px;
    margin-right: 10px;
}

.dedicatoria {
    text-align: center;
    color: #fff;
    font-size: small;
    padding: 20px 10% 10px 10%;
    background: #333;
}

.dedicatoria p{
    margin-bottom: 20px;
    line-height: 1.7rem;
}

.termo{
    position: fixed;
    z-index: 99;
    bottom: 0;
    width: 100%;
    background-color: transparent;
}
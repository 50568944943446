@font-face {
    font-family: "Bebas";
    src: url("./fonts/BebasNeue-Regular.ttf");
}

@font-face {
    font-family: "Cocogoose";
    src: url("./fonts/Cocogoose\ Pro\ Light-trial.ttf");
}

html{
    scroll-behavior: smooth;
    -webkit-overflow-scrolling:touch;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
}

h1, h2, h3, h4, h5{
    font-family: "Bebas";
    letter-spacing: 2px;
}

p{
    font-family: "Cocogoose";
}

label{
    font-family: "Cocogoose";
}

input{
    font-family: "Cocogoose";
}

span{
    font-family: Arial, Helvetica, sans-serif;
}

li{
    font-family: "Bebas";
    letter-spacing: 2px;
}

a{
    font-family: "Cocogoose";
}
@keyframes transFundo {
    0% {background-position: 0px;}
    50% {background-position: center;}
    100% {background-position: 0px;}
}

.espacommv{
    width: 3px;
    
    height: auto;
    background-color: #ddd;
    
    margin-left: 6%;
    margin-right: 3%;
}
.Sobre{
    width: 100%;
    padding: 75px 10% 0 10%;
    overflow: hidden;
    background: url(../../imgs/fundo.jpg);
    background-size: contain;
    color: #fff;
    border-bottom: 10px solid #016fb9aa;
    animation-name: transFundo;
    animation-duration: 12s;
    animation-iteration-count: infinite;
}

.Sobre h1{
    background: linear-gradient(90deg, #ffffff00 0%, #ffffffff 20%, #ffffff00 100%);
    color: #192640;
    margin-right: -30%;
    margin-left: -10%;
    padding: 10px 0 5px 10%;
}

.Sobre .h12{
    background: linear-gradient(90deg, #ffffff00 0%, #ffffffff 50%, #ffffff00 100%);
    color: #192640;
    margin-left: -30%;
    margin-right: -10%;
    padding: 10px 0 5px 30%;
}

.Sobre p{
    padding: 0;
    background: #192640;
    padding-top: 10px;
    margin: 0;
}
.Sobre ul{
    font-family: Arial, Helvetica, sans-serif;
    padding: 0;
    background: #192640;
    padding-top: 10px;
    margin: 0;
}

.Sobre ul li{
    list-style: disc;
    font-family: Arial, Helvetica, sans-serif;
}

.Sobre span{
    font-family: Arial, Helvetica, sans-serif;
}

.Sobre .sobrenos{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 60px;
}
.Sobre .sobrenosvalores{
    width: 100%;
    color: #fff;
    
    flex-direction: column;
    align-items: center;
}
.sobrenostexto{
    width: 45%;
}

.imgsobrenos{
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgsobrenos img{
    width: 100%;
}

.itensCard{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.itemCard{
    width: 220px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.itemCard p{
    background: #ddd;
}

.textoCard{
    background: #ddd;
    color: #333;
    padding: 15px 0 15px 0;
    font-size: small;
    width: 80%;
    height: 210px;
}

.textoCard h3{
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    border-bottom: 2px solid #bbb;
    padding-bottom: 5px;
    margin-bottom: 12px;
}

.textoCard p{
    margin: 0;
    padding: 0;
    line-height: 1.5rem;
    text-align: center;
}

.icocard{
    width: 100%;
    font-size: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    border-radius: 5px 5px 0 0;
}

.c1{
    background: url(../../imgs/card4.jpg);
    background-size: cover;
}

.c2{
    background: url(../../imgs/card1.jpg);
    background-size: cover;
}

.c3{
    background: url(../../imgs/card3.jpg);
    background-size: cover;
}

.c4{
    background: url(../../imgs/card2.jpg);
    background-size: cover;
}

.esquerda{
    width: 10%;
    height: 210px;
    margin-top: -9px;
    background: #dadada;
    -moz-transform: skew(0deg, 40deg);
    -webkit-transform: skew(0deg, 40deg);
    -o-transform: skew(0deg, 40deg);
    -ms-transform: skew(0deg, 40deg);
    transform: skew(0deg, 40deg);
}

.direita{
    width: 10%;
    height: 210px;
    margin-top: -9px;
    background: #dadada;
    -moz-transform: skew(0deg, -40deg);
    -webkit-transform: skew(0deg, -40deg);
    -o-transform: skew(0deg, -40deg);
    -ms-transform: skew(0deg, -40deg);
    transform: skew(0deg, -40deg);
}

.Sobre .space{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
}

.Sobre .space2{
    width: 50%;
    height: 40px;
    background: #ddd;
    transform: skew(45deg);
}

.Sobre .space3{
    width: 50%;
    height: 40px;
    background: #ddd;
    transform: skew(-45deg);
}

@media only screen and (min-width: 800px) {
    .imgsobrenos2{
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    .sobrenos{
        flex-wrap: wrap;
    }

    .sobrenostexto{
        width: 100%;
    }

    .imgsobrenos{
        width: 100%;
        height: 250px;
    }

    .imgsobrenos2 img{
        width: 100%;
    }

    .imgsobrenos2{
        width: 100%;
        height: auto;
    }

    .imgsobrenos3{
        display: none;
    }

    .itensCard{
        justify-content: center;
    }

    .itemCard{
        margin: 0 10px 30px 10px;
    }
}
.Home{
    width: 100%;
}

.Home .video{
  right: 0;
  bottom: 0;
  width: 100%;
}

.barrahome{
  width: 100%;
  height: 100px;
  background: url(../../imgs/barrahome.jpg);
  background-position: center;
}

@media only screen and (max-width: 1000px) {
  .Home{
    padding-top: 80px;
  }
}
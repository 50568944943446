.isodiv{
    position: absolute;
    z-index: 99;
    right: 20px;
    left: calc(100% - 100px);
    margin-top: 4px;
}

button{
    background: transparent;
}

.textoIso{
    width: 400px;
    position: absolute;
    right: 0;
    background: #f6f6f6;
    padding: 20px;
    margin-top: 10px;
    border-radius: 5px;
}

.textoIso p{
    padding: 0;
    padding-bottom: 20px;
    margin: 0;
    font-size: small;
    line-height: 1.6rem;
}

.textoIso span{
    font-family: Arial, Helvetica, sans-serif;
}

.textoIso a{
    font-size: small;
}

@media only screen and (max-width: 1000px) {
    .isodiv{
        left: calc(100% - 75px);
        top: 90px;
    }

    .isodiv button{
        padding: 0;
    }

    .textoIso{
        width: 300px;
    }
}